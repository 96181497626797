/* You can add global styles to this file, and also import other style files */
@import 'https://code.highcharts.com/css/highcharts.css';
@import '../../../../../../sui/components/src/lib/index';
@import '../../../../../../sui/material-icon-font/src/assets/material-icons.scss';
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
@import '~mapbox-gl/dist/mapbox-gl.css';

html {
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    height: 100%;
    padding-top: 64px;
    color: var(--color-foreground-text);
    background-color: var(--color-background-background);
}

* {
    box-sizing: border-box;
}

.cdk-overlay-container {
    z-index: 1005;
}
