@import 'projects/sui/components/src/lib/button/button';
@import 'projects/sui/components/src/lib/tooltip/tooltip';
@import 'projects/sui/components/src/lib/line-chart/line-chart';
@import 'projects/sui/components/src/lib/fab-speed-dial/fab-speed-dial';

.sui-h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 96px;
    line-height: 112px;
    letter-spacing: -0.015em;
}

.sui-h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -0.005em;
}

.sui-h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;
}

.sui-h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.0025em;
}

.sui-h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
}

.sui-h6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
}

.sui-subtitle-1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0015em;
}
.sui-subtitle-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.001em;
}
.sui-body-1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
}

.sui-body-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
}

.sui-caption {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.004em;
}

.sui-overline {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
}

.sui-elevation-1 {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
}

.sui-elevation-2 {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
}

.sui-elevation-3 {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
