[suiButton] {
    display: inline-flex;
    align-items: center;
    height: 48px;
    min-width: 64px;
    padding: 0 8px;
    background-color: transparent;
    outline: none;
    border: none;
    text-transform: uppercase;
    color: var(--color-foreground-text);

    & > span {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        height: 36px;
        width: 100%;
        border-radius: 2px;
        padding: 0 16px;
        line-height: 36px;
        text-align: center;
    }

    &:disabled span {
        color: var(--color-foreground-hint-text);
    }

    &[dense] > span {
        height: 32px;
        line-height: 32px;
        font-size: 13px;
    }

    &[raised]:disabled > span {
        background-color: var(--color-background-disabled-button);
    }

    &.primary:not(.flat) span {
        background-color: var(--color-500);
        color: var(--color-contrast-500);
    }
}

[suiButton]:not(:disabled) {
    cursor: pointer;

    &:hover span,
    &:focus .suiButton {
        background-color: var(--color-background-focused-button);
    }

    &:active span {
        background-color: var(--color-background-selected-button);
    }

    &[raised] > span {
        background-color: var(--color-background-raised-button);
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 5px 0 rgba(0, 0, 0, 0.12);
        transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
            box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &[raised]:active > span {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    &[color='primary']:not([raised]) span {
        color: var(--color-500);
    }

    &[color='primary'][raised] span {
        background-color: var(--color-500);
        color: var(--color-contrast-500);
    }

    &[color='primary'][raised]:active span {
        background-color: var(--color-400);
    }

    &[color='primary']:not([raised]) {
        &:hover span {
            background-color: var(--color-background-hover);
        }

        &:active span {
            background-color: var(--color-background-selected-button);
        }
    }

    &[color='accent']:not([raised]) span {
        color: var(--color-accent-500);
    }

    &[color='accent'][raised] span {
        background-color: var(--color-accent-500);
        color: var(--color-accent-contrast-500);
    }

    &[color='accent'][raised]:active span {
        background-color: var(--color-accent-400);
    }

    &[color='accent']:not([raised]) {
        &:hover span {
            background-color: var(--color-background-hover);
        }

        &:active span {
            background-color: var(--color-background-selected-button);
        }
    }

    &[color='warn']:not([raised]) span {
        color: var(--color-warn-500);
    }

    &[color='warn'][raised] span {
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
    }

    &[color='warn'][raised]:active span {
        background-color: var(--color-warn-400);
    }

    &[color='warn']:not([raised]) {
        &:hover span {
            background-color: var(--color-background-hover);
        }

        &:active span {
            background-color: var(--color-background-selected-button);
        }
    }
}

a[suiButton] {
    &,
    &:focus,
    &:active {
        text-decoration: none;
    }
}
