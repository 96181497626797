/* For use in src/lib/core/theming/_palette.scss */
$planetlighting-primary: (
    50 : #e2e2e2,
    100 : #b7b7b7,
    200 : #878788,
    300 : #565658,
    400 : #323234,
    500 : #0e0e10,
    600 : #0c0c0e,
    700 : #0a0a0c,
    800 : #080809,
    900 : #040405,
    A100 : #4d4dff,
    A200 : #1a1aff,
    A400 : #0000e6,
    A700 : #0000cd,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$planetlighting-secondary: (
    50 : #e4f6f8,
    100 : #bbe9ee,
    200 : #8ddbe2,
    300 : #5fccd6,
    400 : #3dc1ce,
    500 : #1bb6c5,
    600 : #18afbf,
    700 : #14a6b8,
    800 : #109eb0,
    900 : #088ea3,
    A100 : #cff8ff,
    A200 : #9cf0ff,
    A400 : #69e8ff,
    A700 : #50e4ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$planetlighting-warn: (
    50 : #f8fcf1,
    100 : #eef7db,
    200 : #e3f2c4,
    300 : #d8edac,
    400 : #cfe99a,
    500 : #c7e588,
    600 : #c1e280,
    700 : #bade75,
    800 : #b3da6b,
    900 : #a6d358,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #eeffd2,
    A700 : #e4ffb8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);





