sui-line-chart {
    .axis {
        font-size: 10px Roboto sans-serif;
    }

    .axis path,
    .axis line {
        fill: none;
        stroke: #d4d8da;
        stroke-width: 2px;
        shape-rendering: crispEdges;
    }

    .line {
        fill: none;
        stroke-width: 5px;
    }

    rect.overlay {
        fill: none;
        pointer-events: all;
    }

    .focus circle {
        stroke: var(--color-500);
        stroke-width: 5px;
    }

    .hover-line {
        stroke: var(--color-500);
        stroke-width: 2px;
        stroke-dasharray: 3, 3;
    }

    .suiGraph {
        transform: translate(25px, 0px);
    }
}
