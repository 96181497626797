@use '@angular/material' as mat;

@import '../palettes/synapse';
@import '../palettes/ephesus';
@import '../palettes/lsi';
@import '../palettes/planetlighting';
@import '../palettes/signify';
@import '../palettes/specgradeled';
@import '../util/install-theme';

mat.$theme-ignore-duplication-warnings: true;

.theme-ephesus {
    @include install-theme(
        (
            is-dark: false,
            primary: $ephesus-blue-grey,
            accent: $ephesus-pink,
            warn: $synapse-red,
            success: $synapse-green,
        )
    );
}

.theme-eyelighting {
    @include install-theme(
        (
            is-dark: false,
            primary: $synapse-blue,
            accent: $synapse-orange,
            warn: $synapse-red,
            success: $synapse-green,
        )
    );
}

.theme-lsi {
    @include install-theme(
        (
            is-dark: false,
            primary: $lsi-corporate-red,
            accent: $lsi-grey,
            warn: $lsi-amber,
            success: $synapse-green,
        )
    );
}

.theme-planetlighting {
    @include install-theme(
        (
            is-dark: false,
            primary: $planetlighting-primary,
            accent: $planetlighting-secondary,
            warn: $planetlighting-warn,
            success: $synapse-green,
        )
    );
}

.theme-qualite {
    @include install-theme(
        (
            is-dark: false,
            primary: $synapse-blue,
            accent: $synapse-orange,
            warn: $synapse-red,
            success: $synapse-green,
        )
    )
}

.theme-signify {
    @include install-theme(
        (
            is-dark: false,
            primary: $signify-grey,
            accent: $signify-pink,
            warn: $signify-red,
            success: $synapse-green,
        )
    );
}

.theme-specgradeled {
    @include install-theme(
        (
            is-dark: false,
            primary: $specgradeled-blue,
            accent: $specgradeled-bright-blue,
            warn: $specgradeled-red,
            success: $synapse-green,
        )
    );
}

.theme-synapse {
    @include install-theme(
        (
            is-dark: false,
            primary: $synapse-blue,
            accent: $synapse-orange,
            warn: $synapse-red,
            success: $synapse-green,
        )
    );
}

.theme-synapsedark {
    @include install-theme(
        (
            is-dark: true,
            primary: $synapse-light-blue,
            accent: $synapse-light-orange,
            warn: $synapse-red,
            success: $synapse-green,
        )
    );
}

.theme-visionaire {
    @include install-theme(
        (
            is-dark: false,
            primary: $synapse-blue,
            accent: $synapse-orange,
            warn: $synapse-red,
            success: $synapse-green,
        )
    );
}

.theme-fallback {
    @include install-theme(
        (
            is-dark: false,
            primary: $synapse-red,
            accent: $synapse-grey,
            warn: $synapse-red,
            success: $synapse-green,
        )
    );
}
