@use '@angular/material' as mat;
@include mat.core();
@import './light-and-dark';
@import '../palettes/synapse';

@function get-color($theme, $color) {
    @return mat.get-color-from-palette($theme, $color);
}

@function get-contrast-color($theme, $color) {
    @return mat.get-contrast-color-from-palette($theme, $color);
}

@mixin install-theme($palette) {
    $primary: map-get($palette, primary);
    $accent: map-get($palette, accent);
    $warn: map-get($palette, warn);
    $success: map-get($palette, success);
    $is-dark: map-get($palette, is-dark);
    $foreground: if(
        $is-dark,
        mat.$dark-theme-foreground-palette,
        mat.$light-theme-foreground-palette
    );
    $background: if(
        $is-dark,
        mat.$dark-theme-background-palette,
        mat.$light-theme-background-palette
    );

    & {
        --color-50: #{get-color($primary, 50)};
        --color-100: #{get-color($primary, 100)};
        --color-200: #{get-color($primary, 200)};
        --color-300: #{get-color($primary, 300)};
        --color-400: #{get-color($primary, 400)};
        --color-500: #{get-color($primary, 500)};
        --color-600: #{get-color($primary, 600)};
        --color-700: #{get-color($primary, 700)};
        --color-800: #{get-color($primary, 800)};
        --color-900: #{get-color($primary, 900)};

        --color-A100: #{get-color($primary, A100)};
        --color-A200: #{get-color($primary, A200)};
        --color-A400: #{get-color($primary, A400)};
        --color-A700: #{get-color($primary, A700)};

        --color-contrast-50: #{get-contrast-color($primary, 50)};
        --color-contrast-100: #{get-contrast-color($primary, 100)};
        --color-contrast-200: #{get-contrast-color($primary, 200)};
        --color-contrast-300: #{get-contrast-color($primary, 300)};
        --color-contrast-400: #{get-contrast-color($primary, 400)};
        --color-contrast-500: #{get-contrast-color($primary, 500)};
        --color-contrast-600: #{get-contrast-color($primary, 600)};
        --color-contrast-700: #{get-contrast-color($primary, 700)};
        --color-contrast-800: #{get-contrast-color($primary, 800)};
        --color-contrast-900: #{get-contrast-color($primary, 900)};

        --color-contrast-A100: #{get-contrast-color($primary, A100)};
        --color-contrast-A200: #{get-contrast-color($primary, A200)};
        --color-contrast-A400: #{get-contrast-color($primary, A400)};
        --color-contrast-A700: #{get-contrast-color($primary, A700)};

        --color-accent-50: #{get-color($accent, 50)};
        --color-accent-100: #{get-color($accent, 100)};
        --color-accent-200: #{get-color($accent, 200)};
        --color-accent-300: #{get-color($accent, 300)};
        --color-accent-400: #{get-color($accent, 400)};
        --color-accent-500: #{get-color($accent, 500)};
        --color-accent-600: #{get-color($accent, 600)};
        --color-accent-700: #{get-color($accent, 700)};
        --color-accent-800: #{get-color($accent, 800)};
        --color-accent-900: #{get-color($accent, 900)};

        --color-accent-A100: #{get-color($accent, A100)};
        --color-accent-A200: #{get-color($accent, A200)};
        --color-accent-A400: #{get-color($accent, A400)};
        --color-accent-A700: #{get-color($accent, A700)};

        --color-accent-contrast-50: #{get-contrast-color($accent, 50)};
        --color-accent-contrast-100: #{get-contrast-color($accent, 100)};
        --color-accent-contrast-200: #{get-contrast-color($accent, 200)};
        --color-accent-contrast-300: #{get-contrast-color($accent, 300)};
        --color-accent-contrast-400: #{get-contrast-color($accent, 400)};
        --color-accent-contrast-500: #{get-contrast-color($accent, 500)};
        --color-accent-contrast-600: #{get-contrast-color($accent, 600)};
        --color-accent-contrast-700: #{get-contrast-color($accent, 700)};
        --color-accent-contrast-800: #{get-contrast-color($accent, 800)};
        --color-accent-contrast-900: #{get-contrast-color($accent, 900)};

        --color-accent-contrast-A100: #{get-contrast-color($accent, A100)};
        --color-accent-contrast-A200: #{get-contrast-color($accent, A200)};
        --color-accent-contrast-A400: #{get-contrast-color($accent, A400)};
        --color-accent-contrast-A700: #{get-contrast-color($accent, A700)};

        --color-warn-50: #{get-color($warn, 50)};
        --color-warn-100: #{get-color($warn, 100)};
        --color-warn-200: #{get-color($warn, 200)};
        --color-warn-300: #{get-color($warn, 300)};
        --color-warn-400: #{get-color($warn, 400)};
        --color-warn-500: #{get-color($warn, 500)};
        --color-warn-600: #{get-color($warn, 600)};
        --color-warn-700: #{get-color($warn, 700)};
        --color-warn-800: #{get-color($warn, 800)};
        --color-warn-900: #{get-color($warn, 900)};

        --color-warn-A100: #{get-color($warn, A100)};
        --color-warn-A200: #{get-color($warn, A200)};
        --color-warn-A400: #{get-color($warn, A400)};
        --color-warn-A700: #{get-color($warn, A700)};

        --color-warn-contrast-50: #{get-contrast-color($warn, 50)};
        --color-warn-contrast-100: #{get-contrast-color($warn, 100)};
        --color-warn-contrast-200: #{get-contrast-color($warn, 200)};
        --color-warn-contrast-300: #{get-contrast-color($warn, 300)};
        --color-warn-contrast-400: #{get-contrast-color($warn, 400)};
        --color-warn-contrast-500: #{get-contrast-color($warn, 500)};
        --color-warn-contrast-600: #{get-contrast-color($warn, 600)};
        --color-warn-contrast-700: #{get-contrast-color($warn, 700)};
        --color-warn-contrast-800: #{get-contrast-color($warn, 800)};
        --color-warn-contrast-900: #{get-contrast-color($warn, 900)};

        --color-warn-contrast-A100: #{get-contrast-color($warn, A100)};
        --color-warn-contrast-A200: #{get-contrast-color($warn, A200)};
        --color-warn-contrast-A400: #{get-contrast-color($warn, A400)};
        --color-warn-contrast-A700: #{get-contrast-color($warn, A700)};

        --color-success-50: #{get-color($success, 50)};
        --color-success-100: #{get-color($success, 100)};
        --color-success-200: #{get-color($success, 200)};
        --color-success-300: #{get-color($success, 300)};
        --color-success-400: #{get-color($success, 400)};
        --color-success-500: #{get-color($success, 500)};
        --color-success-600: #{get-color($success, 600)};
        --color-success-700: #{get-color($success, 700)};
        --color-success-800: #{get-color($success, 800)};
        --color-success-900: #{get-color($success, 900)};

        --color-success-A100: #{get-color($success, A100)};
        --color-success-A200: #{get-color($success, A200)};
        --color-success-A400: #{get-color($success, A400)};
        --color-success-A700: #{get-color($success, A700)};

        --color-success-contrast-50: #{get-contrast-color($success, 50)};
        --color-success-contrast-100: #{get-contrast-color($success, 100)};
        --color-success-contrast-200: #{get-contrast-color($success, 200)};
        --color-success-contrast-300: #{get-contrast-color($success, 300)};
        --color-success-contrast-400: #{get-contrast-color($success, 400)};
        --color-success-contrast-500: #{get-contrast-color($success, 500)};
        --color-success-contrast-600: #{get-contrast-color($success, 600)};
        --color-success-contrast-700: #{get-contrast-color($success, 700)};
        --color-success-contrast-800: #{get-contrast-color($success, 800)};
        --color-success-contrast-900: #{get-contrast-color($success, 900)};

        --color-foreground-base: #{get-color($foreground, base)};
        --color-foreground-divider: #{get-color($foreground, divider)};
        --color-foreground-dividers: #{get-color($foreground, dividers)};
        --color-foreground-disabled: #{get-color($foreground, disabled)};
        --color-foreground-disabled-button: #{get-color($foreground, disabled-button)};
        --color-foreground-disabled-text: #{get-color($foreground, disabled-text)};
        --color-foreground-hint-text: #{get-color($foreground, hint-text)};
        --color-foreground-secondary-text: #{get-color($foreground, secondary-text)};
        --color-foreground-icon: #{get-color($foreground, icon)};
        --color-foreground-icons: #{get-color($foreground, icons)};
        --color-foreground-text: #{get-color($foreground, text)};
        --color-foreground-slider-min: #{get-color($foreground, slider-min)};
        --color-foreground-slider-off: #{get-color($foreground, slider-off)};
        --color-foreground-slider-off-active: #{get-color($foreground, slider-off-active)};

        --color-background-status-bar: #{get-color($background, status-bar)};
        --color-background-app-bar: #{get-color($background, app-bar)};
        --color-background-background: #{get-color($background, background)};
        --color-background-hover: #{get-color($background, hover)};
        --color-background-card: #{get-color($background, card)};
        --color-background-dialog: #{get-color($background, dialog)};
        --color-background-disabled-button: #{get-color($background, disabled-button)};
        --color-background-raised-button: #{get-color($background, raised-button)};
        --color-background-focused-button: #{get-color($background, focused-button)};
        --color-background-selected-button: #{get-color($background, selected-button)};
        --color-background-selected-disabled-button: #{get-color(
                $background,
                selected-disabled-button
            )};
        --color-background-disabled-button-toggle: #{get-color(
                $background,
                disabled-button-toggle
            )};
        --color-background-unselected-chip: #{get-color($background, unselected-chip)};
        --color-background-disabled-list-option: #{get-color(
                $background,
                disabled-list-option
            )};

        --color-graph-1: #{get-color($synapse-graphs, 6)};
        --color-graph-2: #{get-color($synapse-graphs, 2)};
        --color-graph-3: #{get-color($synapse-graphs, 3)};
        --color-graph-4: #{get-color($synapse-graphs, 4)};
        --color-graph-5: #{get-color($synapse-graphs, 5)};

        --color-equipment-disposition-normal: #{get-color($synapse-map-skittles, normal)};
        --color-equipment-disposition-warning: #{get-color($synapse-map-skittles, warning)};
        --color-equipment-disposition-critical: #{get-color(
                $synapse-map-skittles,
                critical
            )};
        --color-equipment-disposition-unknown: #{get-color($synapse-map-skittles, unknown)};
        --color-equipment-disposition-info: #{get-color($synapse-map-skittles, info)};

        --color-equipment-disposition-normal-contrast: #{get-contrast-color(
                $synapse-map-skittles,
                normal
            )};
        --color-equipment-disposition-warning-contrast: #{get-contrast-color(
                $synapse-map-skittles,
                warning
            )};
        --color-equipment-disposition-critical-contrast: #{get-contrast-color(
                $synapse-map-skittles,
                critical
            )};
        --color-equipment-disposition-unknown-contrast: #{get-contrast-color(
                $synapse-map-skittles,
                unknown
            )};
        --color-equipment-disposition-info-contrast: #{get-contrast-color(
                $synapse-map-skittles,
                info
            )};

        --content-height: calc(100vh - 64px);
        --content-offset: 64px;
        --navbar-offset: 0px;

        // Highchart Stuff
        /* Colors for data series and points */
        // --highcharts-color-0: #2caffe;
        --highcharts-color-0: #{get-color($primary, 500)};
        --highcharts-color-1: #{get-color($warn, 500)};
        --highcharts-color-2: #{get-color($success, 500)};
        --highcharts-color-3: #{get-color($accent, 500)};
        --highcharts-color-4: #6b8abc;
        --highcharts-color-5: #d568fb;
        --highcharts-color-6: #2ee0ca;
        --highcharts-color-7: #fa4b42;
        --highcharts-color-8: #feb56a;
        --highcharts-color-9: #91e8e1;

        /* Chart background, point stroke for markers and columns etc */
        --highcharts-background-color: #{get-color($background, background)};

        /*
        Neutral colors, grayscale by default. The default colors are defined by
        mixing the background-color with neutral, with a weight corresponding to
        the number in the name.
    
        https://www.highcharts.com/samples/highcharts/css/palette-helper
        */

        /* Strong text. */
        --highcharts-neutral-color-100: #{get-color($foreground, secondary-text)};

        /* Main text, axis labels and some strokes. */
        --highcharts-neutral-color-80: #{get-color($foreground, text)};

        /* Axis title, connector fallback. */
        --highcharts-neutral-color-60: #{get-color($foreground, text)};

        /* Credits text, export menu stroke. */
        --highcharts-neutral-color-40: #{get-color($foreground, secondary-text)};

        /* Disabled texts, button strokes, crosshair etc. */
        --highcharts-neutral-color-20: #{get-color($foreground, disabled-text)};

        /* Grid lines etc. */
        --highcharts-neutral-color-10: #{get-color($foreground, disabled-text)};

        /* Minor grid lines etc. */
        --highcharts-neutral-color-5: #{get-color($foreground, secondary-text)};

        /* Tooltip backgroud, button fills, map null points. */
        --highcharts-neutral-color-3: #{get-color($foreground, disabled-text)};

        /*
        Highlights, shades of blue by default
        */

        /* Drilldown clickable labels, color axis max color. */
        --highcharts-highlight-color-100: #{get-color($primary, 900)};

        /* Selection marker, menu hover, button hover, chart border, navigator
        series. */
        --highcharts-highlight-color-80: #{get-color($primary, 800)};

        /* Navigator mask fill. */
        --highcharts-highlight-color-60: #{get-color($primary, 700)};

        /* Ticks and axis line. */
        --highcharts-highlight-color-20: #{get-color($primary, 200)};

        /* Pressed button, color axis min color. */
        --highcharts-highlight-color-10: #{get-color($primary, 100)};

        /* Indicators */
        --highcharts-positive-color: #{get-color($success, 900)};
        --highcharts-negative-color: #{get-color($warn, 900)};
    }

    $mat-primary: mat.define-palette($primary);
    $mat-accent: mat.define-palette($accent);
    $mat-warn: mat.define-palette($warn);

    $mat-light-theme: mat.define-light-theme(
        (
            color: (
                primary: $mat-primary,
                accent: $mat-accent,
                warn: $mat-warn,
            ),
            typography: mat.define-typography-config(),
        )
    );
    $mat-dark-theme: mat.define-dark-theme(
        (
            color: (
                primary: $mat-primary,
                accent: $mat-accent,
                warn: $mat-warn,
            ),
            typography: mat.define-typography-config(),
        )
    );
    $mat-theme: if($is-dark, $mat-dark-theme, $mat-light-theme);

    @include mat.all-component-themes($mat-theme);
    @include mat.typography-hierarchy(mat.define-typography-config());
    @include light-theme();
}
