@import '../palettes/synapse';

@mixin light-theme() {
    & {
        --color-nav-bar: map-get($synapse-grey, 0);
        --color-background: map-get($synapse-grey, 50);
        --color-nav-drawer: map-get($synapse-grey, 0);
        --color-card: map-get($synapse-grey, 0);
        --color-divider: rgba(black, 0.12);
        --color-text: rgba(black, 0.87);
        --color-secondary-text: rgba(black, 0.54);
        --color-hint-text: rgba(black, 0.38);
        --color-icon: rgba(black, 0.54);
        --color-disabled-button: rgba(0, 0, 0, 0.12);
        --color-raised-button: white;
        --color-focused-button: rgba(0, 0, 0, 0.06);
        --color-selected-button: rgba(0, 0, 0, 0.1);
        --color-selected-disabled-button: rgba(0, 0, 0, 0.14);
        --color-disabled-button-toggle: map-get($synapse-grey, 200);

        --mat-menu-item-label-text-size: 14px;
        --mdc-filled-text-field-container-color: null;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0 !important;
    }

    .mdc-button {
        text-transform: uppercase !important;
    }

    mat-error {
        font-size: 75%;
    }
}
